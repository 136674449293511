<template lang="pug">
  .no-data {{ $t("no_data") }}
</template>

<script>
  export default {}
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .no-data
    padding: 25px
    margin: 15px 0
    display: flex
    justify-content: center
    align-items: center
    color: $default-black
    box-shadow: 0 0 1px 1px $default-gray-light
    border-radius: 0.25rem
</style>
